import {isSafari} from 'react-device-detect'
import boardCircleBlack from './board/board-circle-black.png'
import boardCircleBlue from './board/board-circle-blue.png'
import boardCircleGreen from './board/board-circle-green.png'
import boardCircleGrey from './board/board-circle-grey.png'
import boardCircleYellow from './board/board-circle-yellow.png'
import roundTrackerRecto from './board/round-tracker-recto.png'
import roundTrackerVerso from './board/round-tracker-verso.png'
import financierGeneralOff from './characters/financier-general-off.png'
import financierGeneralOn from './characters/financier-general-on.png'
import financierOff from './characters/financier-off.png'
import financierOn from './characters/financier-on.png'
import financierShadowed from './characters/financier-shadowed.png'
import financier from './characters/financier.png'
import generalOff from './characters/general-off.png'
import generalOn from './characters/general-on.png'
import generalShadowed from './characters/general-shadowed.png'
import general from './characters/general.png'
import developmentBack from './developments/development-back.jpg'
import discoveryIcon from './developments/discovery-icon.jpg'
import alexandersTomb from './developments/discovery/alexanders-tomb.jpg'
import ancientAstronauts from './developments/discovery/ancient-astronauts.jpg'
import arkOfTheCovenant from './developments/discovery/ark-of-the-covenant.jpg'
import atlantis from './developments/discovery/atlantis.jpg'
import bermudaTriangle from './developments/discovery/bermuda-triangle.jpg'
import blackBeardsTreasure from './developments/discovery/blackbeards-treasure.jpg'
import centerOfTheEarth from './developments/discovery/center-of-the-earth.jpg'
import citiesOfGold from './developments/discovery/cities-of-gold.jpg'
import cityOfAgartha from './developments/discovery/city-of-agartha.jpg'
import fountainOfYouth from './developments/discovery/fountain-of-youth.jpg'
import gardensOfTheHesperides from './developments/discovery/gardens-of-the-hesperides.jpg'
import islandOfAvalon from './developments/discovery/island-of-avalon.jpg'
import kingSolomonsMines from './developments/discovery/king-solomons-mines.jpg'
import lostContinentOfMu from './developments/discovery/lost-continent-of-mu.jpg'
import parallelDimension from './developments/discovery/parallel-dimension.jpg'
import roswell from './developments/discovery/roswell.jpg'
import treasureOfTheTemplars from './developments/discovery/treasure-of-the-templars.jpg'
import projectIcon from './developments/project-icon.jpg'
import casinoCity from './developments/project/casino-city.jpg'
import espionageAgency from './developments/project/espionage-agency.jpg'
import giantDam from './developments/project/giant-dam.jpg'
import giantTower from './developments/project/giant-tower.jpg'
import harborZone from './developments/project/harbor-zone.jpg'
import lunarBase from './developments/project/lunar-base.jpg'
import magneticTrain from './developments/project/magnetic-train.jpg'
import museum from './developments/project/museum.jpg'
import nationalMonument from './developments/project/national-monument.jpg'
import polarBase from './developments/project/polar-base.jpg'
import propagandaCenter from './developments/project/propaganda-center.jpg'
import secretLaboratory from './developments/project/secret-laboratory.jpg'
import secretSociety from './developments/project/secret-society.jpg'
import solarCannon from './developments/project/solar-cannon.jpg'
import spaceElevator from './developments/project/space-elevator.jpg'
import undergroundCity from './developments/project/underground-city.jpg'
import underwaterCity from './developments/project/underwater-city.jpg'
import universalExposition from './developments/project/universal-exposition.jpg'
import university from './developments/project/university.jpg'
import worldCongress from './developments/project/world-congress.jpg'
import researchIcon from './developments/research-icon.jpg'
import aquaculture from './developments/research/aquaculture.jpg'
import bionicCrafts from './developments/research/bionic-crafts.jpg'
import climateControl from './developments/research/climate-control.jpg'
import cryopreservation from './developments/research/cryopreservation.jpg'
import geneticUpgrades from './developments/research/genetic-upgrades.jpg'
import gravityInverter from './developments/research/gravity-inverter.jpg'
import humanCloning from './developments/research/human-cloning.jpg'
import megaBomb from './developments/research/mega-bomb.jpg'
import neuroscience from './developments/research/neuroscience.jpg'
import quantumGenerator from './developments/research/quantum-generator.jpg'
import robotAssistants from './developments/research/robot-assistants.jpg'
import roboticAnimals from './developments/research/robotic-animals.jpg'
import satellites from './developments/research/satellites.jpg'
import securityAutomatons from './developments/research/security-automatons.jpg'
import superSoldiers from './developments/research/super-soldiers.jpg'
import superSonar from './developments/research/super-sonar.jpg'
import supercomputer from './developments/research/supercomputer.jpg'
import teleportation from './developments/research/teleportation.jpg'
import timeTravel from './developments/research/time-travel.jpg'
import transmutation from './developments/research/transmutation.jpg'
import universalVaccine from './developments/research/universal-vaccine.jpg'
import unknownTechnology from './developments/research/unknown-technology.jpg'
import virtualReality from './developments/research/virtual-reality.jpg'
import structureIcon from './developments/structure-icon.jpg'
import financialCenter from './developments/structure/financial-center.jpg'
import industrialComplex from './developments/structure/industrial-complex.jpg'
import militaryBase from './developments/structure/military-base.jpg'
import nuclearPlant from './developments/structure/nuclear-plant.jpg'
import offshoreOilRig from './developments/structure/offshore-oil-rig.jpg'
import recyclingPlant from './developments/structure/recycling-plant.jpg'
import researchCenter from './developments/structure/research-center.jpg'
import transportationNetwork from './developments/structure/transportation-network.jpg'
import windTurbines from './developments/structure/wind-turbines.jpg'
import vehicleIcon from './developments/vehicle-icon.jpg'
import airborneLaboratory from './developments/vehicle/airborne-laboratory.jpg'
import aircraftCarrier from './developments/vehicle/aircraft-carrier.jpg'
import icebreaker from './developments/vehicle/icebreaker.jpg'
import juggernaut from './developments/vehicle/juggernaut.jpg'
import megaDrill from './developments/vehicle/mega-drill.jpg'
import saucerSquadron from './developments/vehicle/saucer-squadron.jpg'
import submarine from './developments/vehicle/submarine.jpg'
import tankDivision from './developments/vehicle/tank-division.jpg'
import zeppelin from './developments/vehicle/zeppelin.jpg'
import aztecEmpireA from './empires/aztec-empire-A.jpg'
import aztecEmpireArtwork from './empires/aztec-empire-artwork.jpg'
import aztecEmpireAvatar from './empires/aztec-empire-avatar.png'
import aztecEmpireB from './empires/aztec-empire-B.jpg'
import aztecEmpireC from './empires/aztec-empire-C.jpg'
import aztecEmpireD from './empires/aztec-empire-D.jpg'
import federationOfAsiaA from './empires/federation-of-asia-A.jpg'
import federationOfAsiaArtwork from './empires/federation-of-asia-artwork.jpg'
import federationOfAsiaAvatar from './empires/federation-of-asia-avatar.png'
import federationOfAsiaB from './empires/federation-of-asia-B.jpg'
import federationOfAsiaC from './empires/federation-of-asia-C.jpg'
import federationOfAsiaD from './empires/federation-of-asia-D.jpg'
import noramStatesA from './empires/noram-states-A.jpg'
import noramStatesArtwork from './empires/noram-states-artwork.jpg'
import noramStatesAvatar from './empires/noram-states-avatar.png'
import noramStatesB from './empires/noram-states-B.jpg'
import noramStatesC from './empires/noram-states-C.jpg'
import noramStatesD from './empires/noram-states-D.jpg'
import panafricanUnionA from './empires/panafrican-union-A.jpg'
import panafricanUnionArtwork from './empires/panafrican-union-artwork.jpg'
import panafricanUnionAvatar from './empires/panafrican-union-avatar.png'
import panafricanUnionB from './empires/panafrican-union-B.jpg'
import panafricanUnionC from './empires/panafrican-union-C.jpg'
import panafricanUnionD from './empires/panafrican-union-D.jpg'
import republicOfEuropeA from './empires/republic-of-europe-A.jpg'
import republicOfEuropeArtwork from './empires/republic-of-europe-artwork.jpg'
import republicOfEuropeAvatar from './empires/republic-of-europe-avatar.png'
import republicOfEuropeB from './empires/republic-of-europe-B.jpg'
import republicOfEuropeC from './empires/republic-of-europe-C.jpg'
import republicOfEuropeD from './empires/republic-of-europe-D.jpg'
import coverArtwork from './its-cover-artwork.jpg'
import arrowGreen from './menus/arrow-green.png'
import arrowOrange from './menus/arrow-orange.png'
import arrowWhite from './menus/arrow-white.png'
import buttonArrow from './menus/button-arrow.png'
import circleMetal from './menus/circle-metal.png'
import buttonBlack from './menus/menu-black.png'
import buttonBlue from './menus/menu-blue.png'
import buttonYellow from './menus/menu-gold.png'
import buttonGreen from './menus/menu-green.png'
import buttonGrey from './menus/menu-grey.png'
import buttonRed from './menus/menu-red.png'
import textureGrey from './menus/texture-grey.jpg'
import titleBlack from './menus/title-black.png'
import titleBlue from './menus/title-blue.png'
import titleGreen from './menus/title-green.png'
import titleGrey from './menus/title-grey.png'
import titleOrange from './menus/title-orange.png'
import titleWhite from './menus/title-white.png'
import titleYellow from './menus/title-yellow.png'
import energyCube from './resources/energy-cube.png'
import energy from './resources/energy.png'
import explorationCube from './resources/exploration-cube.png'
import exploration from './resources/exploration.png'
import goldCube from './resources/gold-cube.png'
import gold from './resources/gold.png'
import krystalliumCube from './resources/krytallium-cube.png'
import krystallium from './resources/krytallium.png'
import materialsCube from './resources/materials-cube.png'
import materials from './resources/materials.png'
import scienceCube from './resources/science-cube.png'
import science from './resources/science.png'
import scoreBackground from './score-background.png'
import scoreIcon from './score-icon.png'

const Images = {
  boardCircleGrey, boardCircleBlack, boardCircleGreen, boardCircleYellow, boardCircleBlue, roundTrackerRecto, roundTrackerVerso,
  financier, financierShadowed, general, generalShadowed,
  financierOn, financierOff, generalOn, generalOff, financierGeneralOn, financierGeneralOff,
  developmentBack, discoveryIcon, projectIcon, researchIcon, structureIcon, vehicleIcon,
  alexandersTomb, ancientAstronauts, arkOfTheCovenant, atlantis, bermudaTriangle, blackBeardsTreasure, centerOfTheEarth, citiesOfGold, cityOfAgartha,
  fountainOfYouth, gardensOfTheHesperides, islandOfAvalon, kingSolomonsMines, lostContinentOfMu, parallelDimension, roswell, treasureOfTheTemplars,
  casinoCity, espionageAgency, giantDam, giantTower, harborZone, lunarBase, magneticTrain, museum, nationalMonument, polarBase, propagandaCenter,
  secretLaboratory, secretSociety, solarCannon, spaceElevator, undergroundCity, underwaterCity, universalExposition, university, worldCongress,
  aquaculture, bionicCrafts, climateControl, cryopreservation, geneticUpgrades, gravityInverter, humanCloning, megaBomb, neuroscience, quantumGenerator,
  robotAssistants, roboticAnimals, satellites, securityAutomatons, superSoldiers, superSonar, supercomputer, teleportation, timeTravel, transmutation,
  universalVaccine, unknownTechnology, virtualReality,
  financialCenter, industrialComplex, militaryBase, nuclearPlant, offshoreOilRig, recyclingPlant, researchCenter, transportationNetwork, windTurbines,
  airborneLaboratory, aircraftCarrier, icebreaker, juggernaut, megaDrill, saucerSquadron, submarine, tankDivision, zeppelin,
  aztecEmpireA, aztecEmpireArtwork, aztecEmpireAvatar, aztecEmpireB, aztecEmpireC, aztecEmpireD,
  federationOfAsiaA, federationOfAsiaArtwork, federationOfAsiaAvatar, federationOfAsiaB, federationOfAsiaC, federationOfAsiaD,
  noramStatesA, noramStatesArtwork, noramStatesAvatar, noramStatesB, noramStatesC, noramStatesD,
  panafricanUnionA, panafricanUnionArtwork, panafricanUnionAvatar, panafricanUnionB, panafricanUnionC, panafricanUnionD,
  republicOfEuropeA, republicOfEuropeArtwork, republicOfEuropeAvatar, republicOfEuropeB, republicOfEuropeC, republicOfEuropeD,
  arrowGreen, arrowOrange, arrowWhite, buttonArrow,
  buttonBlack, buttonBlue, buttonGreen, buttonGrey, buttonRed, buttonYellow, textureGrey,
  circleMetal, titleBlack, titleBlue, titleGreen, titleGrey, titleOrange, titleWhite, titleYellow,
  energy, energyCube, exploration, explorationCube, gold, goldCube, krystallium, krystalliumCube, materials, materialsCube, science, scienceCube,
  coverArtwork, scoreBackground, scoreIcon
}

if (!isSafari) {
  for (const image in Images) {
    const match = Images[image].match(/(.*)\.(jpg|png)$/)
    if (match) {
      Images[image] = match[1] + '.webp'
    }
  }
}

export default Images
import Development from '@gamepark/its-a-wonderful-world/material/Development'
import {
  AirborneLaboratory, AircraftCarrier, AlexandersTomb, AncientAstronauts, Aquaculture, ArkOfTheCovenant, Atlantis, BermudaTriangle, BionicCrafts,
  BlackBeardsTreasure, CasinoCity, CenterOfTheEarth, CitiesOfGold, CityOfAgartha, ClimateControl, Cryopreservation, EspionageAgency, FinancialCenter,
  FountainOfYouth, GardensOfTheHesperides, GeneticUpgrades, GiantDam, GiantTower, GravityInverter, HarborZone, HumanCloning, Icebreaker, IndustrialComplex,
  IslandOfAvalon, Juggernaut, KingSolomonsMines, LostContinentOfMu, LunarBase, MagneticTrain, MegaBomb, MegaDrill, MilitaryBase, Museum, NationalMonument,
  Neuroscience, NuclearPlant, OffshoreOilRig, ParallelDimension, PolarBase, PropagandaCenter, QuantumGenerator, RecyclingPlant, ResearchCenter, RobotAssistants,
  RoboticAnimals, Roswell, Satellites, SaucerSquadron, SecretLaboratory, SecretSociety, SecurityAutomatons, SolarCannon, SpaceElevator, Submarine,
  Supercomputer, SuperSoldiers, SuperSonar, TankDivision, Teleportation, TimeTravel, Transmutation, TransportationNetwork, TreasureOfTheTemplars,
  UndergroundCity, UnderwaterCity, UniversalExposition, UniversalVaccine, University, UnknownTechnology, VirtualReality, WindTurbines, WorldCongress, Zeppelin
} from '@gamepark/its-a-wonderful-world/material/Developments'
import {TFunction} from 'i18next'

const DevelopmentCardsTitles = new Map<Development, (t: TFunction) => string>()

DevelopmentCardsTitles.set(FinancialCenter, (t: TFunction) => t('Financial Center'))
DevelopmentCardsTitles.set(IndustrialComplex, (t: TFunction) => t('Industrial Complex'))
DevelopmentCardsTitles.set(MilitaryBase, (t: TFunction) => t('Military Base'))
DevelopmentCardsTitles.set(NuclearPlant, (t: TFunction) => t('Nuclear Plant'))
DevelopmentCardsTitles.set(OffshoreOilRig, (t: TFunction) => t('Offshore Oil Rig'))
DevelopmentCardsTitles.set(RecyclingPlant, (t: TFunction) => t('Recycling Plant'))
DevelopmentCardsTitles.set(ResearchCenter, (t: TFunction) => t('Research Center'))
DevelopmentCardsTitles.set(TransportationNetwork, (t: TFunction) => t('Transportation Network'))
DevelopmentCardsTitles.set(WindTurbines, (t: TFunction) => t('Wind Turbines'))
DevelopmentCardsTitles.set(AirborneLaboratory, (t: TFunction) => t('Airborne Laboratory'))
DevelopmentCardsTitles.set(AircraftCarrier, (t: TFunction) => t('Aircraft Carrier'))
DevelopmentCardsTitles.set(Icebreaker, (t: TFunction) => t('Icebreaker'))
DevelopmentCardsTitles.set(Juggernaut, (t: TFunction) => t('Juggernaut'))
DevelopmentCardsTitles.set(MegaDrill, (t: TFunction) => t('Mega-Drill'))
DevelopmentCardsTitles.set(SaucerSquadron, (t: TFunction) => t('Saucer Squadron'))
DevelopmentCardsTitles.set(Submarine, (t: TFunction) => t('Submarine'))
DevelopmentCardsTitles.set(TankDivision, (t: TFunction) => t('Tank Division'))
DevelopmentCardsTitles.set(Zeppelin, (t: TFunction) => t('Zeppelin'))
DevelopmentCardsTitles.set(Aquaculture, (t: TFunction) => t('Aquaculture'))
DevelopmentCardsTitles.set(BionicCrafts, (t: TFunction) => t('Bionic Grafts'))
DevelopmentCardsTitles.set(ClimateControl, (t: TFunction) => t('Climate Control'))
DevelopmentCardsTitles.set(Cryopreservation, (t: TFunction) => t('Cryopreservation'))
DevelopmentCardsTitles.set(GeneticUpgrades, (t: TFunction) => t('Genetic Upgrades'))
DevelopmentCardsTitles.set(GravityInverter, (t: TFunction) => t('Gravity Inverter'))
DevelopmentCardsTitles.set(HumanCloning, (t: TFunction) => t('Human Cloning'))
DevelopmentCardsTitles.set(MegaBomb, (t: TFunction) => t('Mega-Bomb'))
DevelopmentCardsTitles.set(Neuroscience, (t: TFunction) => t('Neuroscience'))
DevelopmentCardsTitles.set(QuantumGenerator, (t: TFunction) => t('Quantum Generator'))
DevelopmentCardsTitles.set(RobotAssistants, (t: TFunction) => t('Robot Assistants'))
DevelopmentCardsTitles.set(RoboticAnimals, (t: TFunction) => t('Robotic Animals'))
DevelopmentCardsTitles.set(Satellites, (t: TFunction) => t('Satellites'))
DevelopmentCardsTitles.set(SecurityAutomatons, (t: TFunction) => t('Security Automatons'))
DevelopmentCardsTitles.set(SuperSoldiers, (t: TFunction) => t('Super-Soldiers'))
DevelopmentCardsTitles.set(SuperSonar, (t: TFunction) => t('Super-Sonar'))
DevelopmentCardsTitles.set(Supercomputer, (t: TFunction) => t('Supercomputer'))
DevelopmentCardsTitles.set(Teleportation, (t: TFunction) => t('Teleportation'))
DevelopmentCardsTitles.set(TimeTravel, (t: TFunction) => t('Time Travel'))
DevelopmentCardsTitles.set(Transmutation, (t: TFunction) => t('Transmutation'))
DevelopmentCardsTitles.set(UniversalVaccine, (t: TFunction) => t('Universal Vaccine'))
DevelopmentCardsTitles.set(UnknownTechnology, (t: TFunction) => t('Unknown Technology'))
DevelopmentCardsTitles.set(VirtualReality, (t: TFunction) => t('Virtual Reality'))
DevelopmentCardsTitles.set(CasinoCity, (t: TFunction) => t('Casino City'))
DevelopmentCardsTitles.set(EspionageAgency, (t: TFunction) => t('Espionage Agency'))
DevelopmentCardsTitles.set(GiantDam, (t: TFunction) => t('Giant Dam'))
DevelopmentCardsTitles.set(GiantTower, (t: TFunction) => t('Giant Tower'))
DevelopmentCardsTitles.set(HarborZone, (t: TFunction) => t('Harbor Zone'))
DevelopmentCardsTitles.set(LunarBase, (t: TFunction) => t('Lunar Base'))
DevelopmentCardsTitles.set(MagneticTrain, (t: TFunction) => t('Magnetic Train'))
DevelopmentCardsTitles.set(Museum, (t: TFunction) => t('Museum'))
DevelopmentCardsTitles.set(NationalMonument, (t: TFunction) => t('National Monument'))
DevelopmentCardsTitles.set(PolarBase, (t: TFunction) => t('Polar Base'))
DevelopmentCardsTitles.set(PropagandaCenter, (t: TFunction) => t('Propaganda Center'))
DevelopmentCardsTitles.set(SecretLaboratory, (t: TFunction) => t('Secret Laboratory'))
DevelopmentCardsTitles.set(SecretSociety, (t: TFunction) => t('Secret Society'))
DevelopmentCardsTitles.set(SolarCannon, (t: TFunction) => t('Solar Cannon'))
DevelopmentCardsTitles.set(SpaceElevator, (t: TFunction) => t('Space Elevator'))
DevelopmentCardsTitles.set(UndergroundCity, (t: TFunction) => t('Underground City'))
DevelopmentCardsTitles.set(UnderwaterCity, (t: TFunction) => t('Underwater City'))
DevelopmentCardsTitles.set(UniversalExposition, (t: TFunction) => t('Universal Exposition'))
DevelopmentCardsTitles.set(University, (t: TFunction) => t('University'))
DevelopmentCardsTitles.set(WorldCongress, (t: TFunction) => t('World Congress'))
DevelopmentCardsTitles.set(AlexandersTomb, (t: TFunction) => t('Alexander’s Tomb'))
DevelopmentCardsTitles.set(AncientAstronauts, (t: TFunction) => t('Ancient Astronauts'))
DevelopmentCardsTitles.set(ArkOfTheCovenant, (t: TFunction) => t('Ark Of The Covenant'))
DevelopmentCardsTitles.set(Atlantis, (t: TFunction) => t('Atlantis'))
DevelopmentCardsTitles.set(BermudaTriangle, (t: TFunction) => t('Bermuda Triangle'))
DevelopmentCardsTitles.set(BlackBeardsTreasure, (t: TFunction) => t('Blackbeard’s Treasure'))
DevelopmentCardsTitles.set(CenterOfTheEarth, (t: TFunction) => t('Center Of The Earth'))
DevelopmentCardsTitles.set(CitiesOfGold, (t: TFunction) => t('Cities Of Gold'))
DevelopmentCardsTitles.set(CityOfAgartha, (t: TFunction) => t('City Of Agartha'))
DevelopmentCardsTitles.set(FountainOfYouth, (t: TFunction) => t('Fountain Of Youth'))
DevelopmentCardsTitles.set(GardensOfTheHesperides, (t: TFunction) => t('Gardens Of The Hesperides'))
DevelopmentCardsTitles.set(IslandOfAvalon, (t: TFunction) => t('Island Of Avalon'))
DevelopmentCardsTitles.set(KingSolomonsMines, (t: TFunction) => t('King Solomon’s Mines'))
DevelopmentCardsTitles.set(LostContinentOfMu, (t: TFunction) => t('Lost Continent Of Mu'))
DevelopmentCardsTitles.set(ParallelDimension, (t: TFunction) => t('Parallel Dimension'))
DevelopmentCardsTitles.set(Roswell, (t: TFunction) => t('Roswell'))
DevelopmentCardsTitles.set(TreasureOfTheTemplars, (t: TFunction) => t('Treasure Of The Templars'))

export default DevelopmentCardsTitles